import { Link } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import * as styles from "./sectionLayout.module.css";
import { ScreenSize } from "../../utils/helpers/helper";

// export default function TitleDescription({ Title, Description, isPlatForm }) {
//   return (
//     <div className={styles?.headingContainer}>
//       <h1 className="heading-quaternary-lg">{Title}</h1>
//       {isPlatForm ? (
//         <p className={`title-primary-sm fw-500 ${styles?.descriptionPlatform}`}>
//           {Description?.substring(0, ScreenSize.isMobile() ? 70 : 100)}...
//           <span>
//             <Link to="/platform">Read More</Link>
//           </span>
//         </p>
//       ) : (
//         <p className={`title-primary-sm fw-500`}>{Description}</p>
//       )}
//     </div>
//   );
// }

export default function TitleDescription({
  Title,
  Description,
  isPlatForm,
  fontColor = "black",
}) {
  const fontColorClass =
    fontColor === "white" ? styles.whiteText : styles.blackText;

  return (
    <div
      className={`${styles?.headingContainer} ${fontColorClass} fullWidth`}
    >
      <h1 className={`heading-quaternary-lg ${fontColorClass}`}>{Title}</h1>
      {isPlatForm ? (
        <p
          className={`title-primary-sm fw-500 ${styles?.descriptionPlatform} ${fontColorClass}`}
        >
          {Description?.substring(0, ScreenSize.isMobile() ? 70 : 100)}...
          <span>
            <Link to="/platform" className={fontColorClass}>
              Read More
            </Link>
          </span>
        </p>
      ) : (
        <p className={`title-primary-sm fw-500 ${fontColorClass}`}>
          {Description}
        </p>
      )}
    </div>
  );
}

export const DesignationDescription = ({
  name,
  company,
  designation,
  Description,
  socialIconElement,
}) => {
  // const [isVisible, setIsVisible] = useState(false);
  // useEffect(() => {
  //   let setTimeoutId;
  //   setIsVisible(false);
  //   setTimeoutId = setTimeout(() => {
  //     setIsVisible(true); // Reset visibility to trigger fade-in
  //   }, 500);
  //   return () => {
  //     clearTimeout(setTimeoutId);
  //   };
  // }, [name, company, designation, Description, socialIconElement]);
  return (
    <div
      className={`${styles?.DesginationContainer} 
      `}
      // ${
      //   isVisible ? styles.fadeIn : styles.fadeOut
      // }
    >
      <div className={styles?.DesginationTitle}>
        <p className="title-primary-sm">{name}</p>
        <p className={`${styles?.DesginationName} body-sm-upper`}>
          {designation}
          <span>{company ? "," + company : ""}</span>
        </p>
        <p className={styles?.DesginationCompany}>{company ? company : ""}</p>
      </div>
      <div>{socialIconElement}</div>
      <div className={styles?.DesginationDescription}>
        <p className={`subheading-tertiary-sm`}>{Description}</p>
      </div>
    </div>
  );
};

export function CircleDescriptionTitle({ title, description }) {
  return (
    <div className={styles?.CircleDescriptionTitle}>
      <p className="subheading-secondary-sm">{title}</p>
      <p className={`subheading-tertiary-sm ${styles?.descriptionText}`}>
        {description}
      </p>
    </div>
  );
}

export const MisionVisonTitleDesc = ({ title, description, LifeAtKpoint }) => {
  return (
    <div className={styles?.MisionVisonTitleDesc}>
      <h3
        className={
          LifeAtKpoint ? "heading-quaternary-lg" : "heading-quaternary-lg"
        }
      >
        {title}
      </h3>
      <p className="title-primary-sm">{description}</p>
    </div>
  );
};

export const PlatformTitleDesc = ({ title, description }) => {
  return (
    <div className={styles?.PlatformTitleDesc}>
      <h3 className="heading-quaternary-lg">{title}</h3>
      <p className="title-primary-sm fw-500">{description}</p>
    </div>
  );
};

export const UseCaseTitleDesc = ({ title, description }) => {
  return (
    <div className={styles?.useCaseTitleDesc}>
      <h2 className="heading-quaternary-lg">{title}</h2>
      <p className="title-primary-sm fw-500">{description}</p>
    </div>
  );
};

export const ClientsNameDesignation = ({ name, designation, company }) => {
  return (
    <div className={styles.clientsNameDesignation}>
      <p className={styles.heading} style={{ fontWeight: "600" }}>
        {name}
      </p>
      |
      <p className={styles.heading}>
        {designation} {company}
      </p>
    </div>
  );
};

export const ClientsDescription = ({ Description }) => {
  return (
    <div className={styles.clientsDescription}>
      <p className={styles.description}>{Description}</p>
    </div>
  );
};
