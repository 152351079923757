import React from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import "./typography.css";
import KpointPlayerProvider from "../../../context/KpointPlayerProvider";
import { ObserverProvider } from "../../../context/ObserverContext";

const Layout = ({ children }) => {

  const isConnectPage =
    children.key.includes("manish/connect/")  
  
  return (
    <>
      <ObserverProvider>
        <KpointPlayerProvider>
          <div className="layout">
            {!isConnectPage && <Header />}
            <main>{children}</main>
            {!isConnectPage && <Footer />}
          </div>
          <div id="portal-root"></div>
        </KpointPlayerProvider>
      </ObserverProvider>
    </>
  );
};

export default Layout;
